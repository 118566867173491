








import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import PromoSideBarLeft from '@/_modules/promo/components/side-bar-left/side-bar-left.vue';
import PromoSideBarRight from '@/_modules/promo/components/side-bar-right/side-bar-right.vue';
import MenuHintsGuide from '@/_modules/promo/components/menu-hints-guide/menu-hints-guide.vue';
import SeoHelper from '@/_helpers/seo-helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import eventDiscoveryService, { TEventDiscoveryServiceConfig } from '@/_services/event-discovery.service';

const ONLINE_CONTACTS_POLLING_INTERVAL = 3000;

@Component({
  components: {
    PromoSideBarLeft,
    PromoSideBarRight,
    MenuHintsGuide
  },
})
export default class Promo extends Vue {

  @Getter('authStore/isAuthenticated') readonly isAuthenticated: boolean;
  @Getter('promoPageStore/contact') readonly myself: TContact;
  @Getter('_eventStore/event') readonly event: TEvent;

  @Action('contactsStore/clearOnlineContactIds') clearOnlineContactIds: () => void;

  public readonly isOBSSettingsDialogVisible: boolean;
  public readonly isZoomSettingsDialogVisible: boolean;
  public readonly isBroadcastTimeCheckDialogVisible: boolean;
  public readonly isEmbedCodeDialogVisible: boolean;

  private destroyed$: Subject<void> = new Subject<void>();
  public isServiceConnected: boolean = false;
  public onlineContactsIntervalId: number = 0;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  @Watch('$route.name', { immediate: true })
  public onRouteNameChange(): void {
    this.enforceContactInfoInviter();
  }

  @Watch('event.title', {immediate: true})
  private onEventTitleChanged(): void {
    if (!this.event || (process.env.VUE_APP_NAME !== this.event.title)) {
      this.manageSeoInfo();
    }
  }

  @Watch('myself', {immediate: true})
  private onContactInfoChange(): void {
    if (this.myself) {
      this.subscribeToContactEvents();
    }
  }

  @Watch('isServiceConnected', { immediate: true })
  private onIsServiceConnectedChange(): void {
    if (this.isServiceConnected) {
      eventDiscoveryService.subscribe('');
      eventDiscoveryService.requestOnlineContacts();
      this.startPollingOnlineContacts();
    } else {
      this.stopPollingOnlineContacts();
      this.clearOnlineContactIds();
    }
  }

  private startPollingOnlineContacts(): void {
    this.onlineContactsIntervalId = window.setInterval(() => {
      eventDiscoveryService.requestOnlineContacts();
    }, ONLINE_CONTACTS_POLLING_INTERVAL)
  }

  private stopPollingOnlineContacts(): void {
    window.clearInterval(this.onlineContactsIntervalId);
  }

  private onServiceConnectChange(config: TEventDiscoveryServiceConfig): void {
    this.isServiceConnected = !!config;
  }

  // TODO: this block needs refactoring
  public created(): void {
    if (this.myself && this.myself.id) {
      return;
    }
    this.$store.dispatch('promoPageStore/getContact', { eventId: this.eventId });
    this.enforceContactInfoInviter();
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.stopPollingOnlineContacts();
    this.clearOnlineContactIds();
  }

  private subscribeToContactEvents(): void {
    eventDiscoveryService.connected$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(this.onServiceConnectChange.bind(this));
  }

  public enforceContactInfoInviter(): void {
    this.$store.dispatch('authStore/setContactInfoInviterBackgroundMode', true);
    this.$store.dispatch('authStore/setContactInfoInviterVisibility', true);
  }

  private manageSeoInfo(): void {
    if (!this.event) {
      return;
    }
    SeoHelper.setEventPageTitle(this.event, this.event.title);
  }

}
